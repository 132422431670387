import React, { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import styled from "styled-components";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useWallet } from "@solana/wallet-adapter-react";
import Countdown from "react-countdown";

const VaultItemAdvanced = ({
  nft,
  type,
  action,
  conn,
  claimAction,
  bank,
}: {
  nft: any;
  type: string;
  action: any;
  conn: any;
  claimAction?: any;
  bank?: any;
}) => {
  const { publicKey } = useWallet();

  const [dateStaked, setDateStaked] = useState(null);
  const [dateCooldown, setDateCooldown] = useState(null);
  const [estEarn, setEstEarn] = useState(0);
  const [rewardRate, setRewardRate] = useState(8);
  // const [farmer, setFarmer] = useState(null);
  const image = nft?.json ? nft?.json?.image : nft?.image;
  const name = nft?.json ? nft?.json?.name : nft?.name;

  useEffect(() => {
    async function fetchTransactionDate() {
      try {
        if (type === "staked") {
          const sigs = await conn.getSignaturesForAddress(
            new PublicKey(nft?.farmer?.publicKey?.toString()),
            {
              limit: 1,
            }
          );

          const trans = await conn.getParsedTransaction(sigs[0].signature);
          const amount = trans?.meta?.postTokenBalances?.find(
            (a) =>
              a.mint === "2qayh5Qc3BBR91KA6CemKN7vmzmexRJJw2KWFPzn53Uw" &&
              a.owner === publicKey.toString()
          )?.uiTokenAmount?.amount;

          if (
            trans &&
            trans?.meta?.logMessages?.includes(
              ("Program log: Instruction: Claim" && amount > 0) ||
                "Program log: Instruction: Stake"
            )
          ) {
            setDateStaked(dayjs.unix(sigs[0]?.blockTime));
          } else {
            const newSigs = await conn.getSignaturesForAddress(
              new PublicKey(nft?.farmer?.publicKey?.toString()),
              {
                limit: 10,
              }
            );

            let newArray = [];

            for (var i = 0; i < newSigs.length; i++) {
              var value = newSigs[i];
              const trans = await conn.getParsedTransaction(value.signature);

              if (
                trans &&
                trans?.meta?.logMessages?.includes(
                  ("Program log: Instruction: Claim" && amount > 0) ||
                    "Program log: Instruction: Stake"
                )
              ) {
                newArray.push(trans);
              }
            }

            const data = newArray[0];

            if (data) {
              setDateStaked(dayjs.unix(data.blockTime));
            } else {
              setDateStaked(dayjs.unix(sigs[0]?.blockTime));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchTransactionDate();
  }, [conn, nft?.farmer?.publicKey, nft.mint, publicKey, type]);

  useEffect(() => {
    if (type === "staked") {
      setDateCooldown(
        dayjs.unix(nft?.farmer?.account?.minStakingEndsTs.toString())
      );
    }
  }, [nft?.farmer?.account?.minStakingEndsTs, type]);

  useEffect(() => {
    const data =
      bank?.value === "two"
        ? 19
        : bank?.value === "three"
        ? 24
        : bank?.value === "four"
        ? 30
        : 15;

    setRewardRate(data);
  }, [bank?.value]);

  useEffect(() => {
    async function fetchTransaction() {
      try {
        if (type === "staked") {
          if (dateStaked) {
            let diffInCoinsBase = 0;
            const earningsBase = rewardRate;

            const diffInSecBase = dayjs(new Date()).diff(dateStaked, "seconds");
            diffInCoinsBase = diffInSecBase * (earningsBase / 86400);
            setEstEarn(diffInCoinsBase);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    const interval = setInterval(() => fetchTransaction(), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [conn, dateStaked, nft.mint, rewardRate, type]);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
    } else {
      // Render a countdown
      return (
        <span>
          {String(days).padStart(2, "0")}:{String(hours).padStart(2, "0")}:
          {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </span>
      );
    }
  };

  return (
    <VaultItemContainer>
      <img src={image} alt={name} />
      <VaultItemDetailsContainer>
        <p style={{ marginBottom: 4 }}>{name}</p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 400,
            marginBottom: 8,
          }}
        >
          Reward Rate: {rewardRate} $BELL
        </p>
        {type === "staked" && (
          <p
            style={{
              fontSize: 10,
              marginBottom: 12,
            }}
          >
            Total $BELL Earned: {estEarn.toFixed(3)}
          </p>
        )}
        <VaultItemButtonsContainer>
          {type === "staked" && claimAction && (
            <>
              {dateCooldown?.diff(new Date(), "second") < 0 ? (
                <button className="button" onClick={() => claimAction(nft)}>
                  Claim
                </button>
              ) : (
                dateStaked?.add(10, "day").diff(new Date(), "second") < 0 && (
                  <button className="button" onClick={() => claimAction(nft)}>
                    Claim
                  </button>
                )
              )}
            </>
          )}
          <button
            className={
              type === "staked"
                ? dateCooldown > dayjs(new Date())
                  ? "button button-secondary"
                  : "button"
                : "button"
            }
            onClick={
              type === "staked"
                ? dateCooldown > dayjs(new Date())
                  ? () => toast.error(`Noti Gang is still locked up.`)
                  : () => action(nft)
                : () => action(nft)
            }
          >
            {type === "staked" ? (
              dateCooldown > dayjs(new Date()) ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    style={{ marginRight: 4 }}
                  >
                    <path
                      d="M17 9.761V5A5 5 0 1 0 7 5v4.761A7.98 7.98 0 0 0 4 16a8 8 0 0 0 16 0 7.98 7.98 0 0 0-3-6.239zM9 5c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587a7.96 7.96 0 0 0-6 0V5zm3 17c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm2-6a2 2 0 1 1-4.001-.001A2 2 0 0 1 14 16z"
                      fill="#0b843c"
                    />
                  </svg>
                  <Countdown date={dateCooldown} renderer={renderer} />
                </>
              ) : (
                "Free"
              )
            ) : (
              "Jail"
            )}
          </button>
        </VaultItemButtonsContainer>
      </VaultItemDetailsContainer>
    </VaultItemContainer>
  );
};

export default VaultItemAdvanced;

const VaultItemContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  flex-direction: column;
  max-width: 270px;
  overflow: hidden;

  img {
    height: 180px;
    object-fit: cover;

    @media only screen and (min-width: 700px) {
      height: 270px;
    }
  }
`;

const VaultItemDetailsContainer = styled.div`
  padding: 12px;

  p {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin: 0;
    margin-bottom: 4px;
  }
`;

const VaultItemButtonsContainer = styled.div`
  display: flex;
  margin-left: -4px;
  margin-right: -4px;

  button {
    border-radius: 4px;
    box-shadow: none;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    height: 36px;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    /* margin-top: 8px; */
    width: 100%;

    &:hover {
      /* background: #020b1c; */
    }
  }
`;
