import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
// import { clusterApiUrl } from "@solana/web3.js";
import { useCallback, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Home from "containers/home";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import TinyDogzLogo from "./images/tinydogz.png";


const App = () => {
  const [loading, setLoading] = useState(false)
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  // const network = WalletAdapterNetwork.Devnet;
  const network = WalletAdapterNetwork.Mainnet;
  // const network = process.env.REACT_APP_RPC_ID
  // You can also provide a custom RPC endpoint
  const endpoint = process.env.REACT_APP_RPC_ID
  // const endpoint =
  //   "https://cold-small-meadow.solana-mainnet.quiknode.pro/4d5ba9470c46ab34821fae34c32664c71ca5c40f/";
  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getTorusWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  const onError = useCallback(
    (error) => {
      toast.error(
        error.message ? `${error.name}: ${error.message}` : error.name, {
        duration: 3000
      });
      setLoading(false);
    },
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect>
        <WalletModalProvider>
          <Home loading={loading} setLoading={setLoading} />
          <Footer>
            <Container>
              <p>
                <a
                  href="https://www.twitter.com/TinyDogzNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  Powered by Tiny Dogz
                  <img src={TinyDogzLogo} alt="Tiny Dogz" height={32} />
                </a>
              </p>
            </Container>
          </Footer>
        </WalletModalProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </WalletProvider>
    </ConnectionProvider>
  );
};


export default App

const Footer = styled.div`
  padding: 30px 0;
  position: relative;
  z-index: 1;

  p {
    font-family: 'Work Sans', serif;
    font-size: 14px;
    font-weight: 600;

    a {
      color: #fff;
      text-decoration: none;
    }

    img {
      margin-left: 8px;
      position: relative;
      top: -2px;
    }
  }
`;
