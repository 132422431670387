import { useEffect, useRef, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { PublicKey } from "@solana/web3.js";
import {
  claimNft,
  fetchFarm,
  getNFTsByOwner,
  initGemFarm,
  populateVaultNFTs,
  stakeNft,
  unstakeNft,
} from "helpers/functions";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import styled from "styled-components";
import Loading from "react-loading-spinkit";

import Logo from "../images/logo.png";
import Background from "../images/background.png";
import {
  Token,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { Container, ProgressBar } from "react-bootstrap";
import VaultItemAdvanced from "components/VaultItemAdvanced";
import { ProgramAccount } from "@project-serum/anchor";

const options = [
  {
    value: "one",
    label: "Lockup: 10 Days",
    farmId: process.env.REACT_APP_FARM_ID,
  },
  {
    value: "two",
    label: "Lockup: 20 Days",
    farmId: process.env.REACT_APP_FARM_ID_TWO,
  },
  {
    value: "three",
    label: "Lockup: 30 Days",
    farmId: process.env.REACT_APP_FARM_ID_THREE,
  },
  {
    value: "four",
    label: "Lockup: 60 Days",
    farmId: process.env.REACT_APP_FARM_ID_FOUR,
  },
];

const Home = ({ loading, setLoading }) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  const { connection } = useConnection();
  const { wallet, publicKey, sendTransaction } = useWallet();

  const [allFarmersFromWallet, setAllFarmersFromWallet] = useState(
    new Array<ProgramAccount>()
  );

  const [bank, setBank] = useState(null);
  const [option, setOption] = useState(options[0]);

  // const [rewardValue, setRewardValue] = useState(0);

  const [farm, setFarm] = useState(null);
  const [farmOne, setFarmOne] = useState(null);
  const [farmTwo, setFarmTwo] = useState(null);
  const [farmThree, setFarmThree] = useState(null);
  const [farmFour, setFarmFour] = useState(null);
  const [tokenAmount, setTokenAmount] = useState(null);

  const [stakedNfts, setStakedNfts] = useState(null);
  const [unstakedNfts, setUnstakedNfts] = useState(null);

  useEffect(() => {
    async function fetchFarmFunc() {
      // grab farm on load
      if (connection) {
        const farm = await fetchFarm(
          new PublicKey(bank?.farmId),
          connection,
          null
        );
        setFarm(farm);
      }
    }

    fetchFarmFunc();
  }, [bank?.farmId, connection, publicKey, wallet]);

  useEffect(() => {
    async function fetchFarmFunc() {
      // grab farm on load
      if (farm) {
        const farmA = await fetchFarm(
          new PublicKey(process.env.REACT_APP_FARM_ID),
          connection,
          null
        );
        setFarmOne(farmA);
      }

      if (farm) {
        const farmB = await fetchFarm(
          new PublicKey(process.env.REACT_APP_FARM_ID_TWO),
          connection,
          null
        );
        setFarmTwo(farmB);
      }

      if (farm) {
        const farmC = await fetchFarm(
          new PublicKey(process.env.REACT_APP_FARM_ID_THREE),
          connection,
          null
        );
        setFarmThree(farmC);
      }

      if (farm) {
        const farmD = await fetchFarm(
          new PublicKey(process.env.REACT_APP_FARM_ID_FOUR),
          connection,
          null
        );
        setFarmFour(farmD);
      }
    }

    fetchFarmFunc();
  }, [connection, farm]);

  useEffect(() => {
    if (option) {
      setBank(option);
    }
  }, [option]);

  useEffect(() => {
    if (publicKey!) {
      refreshAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    if (publicKey! && bank) {
      refreshAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey, bank]);

  async function refreshAll() {
    setLoading(true);
    await fetchAllFarmers();
    await getUnstakedNfts();
    await getStakedNfts();
    setLoading(false);
  }

  async function getUnstakedNfts() {
    const walletNfts = await getNFTsByOwner(connection, publicKey);
    await setUnstakedNfts(walletNfts);
  }

  const claimAction = async (nft) => {
    await claimNft(
      new PublicKey(bank.farmId),
      connection,
      wallet,
      publicKey,
      nft.mint,
      sendTransaction
    );

    await refreshAll();
    refreshAll();
  };

  const stakeAction = async (nft) => {
    setLoading(true);
    const creator = new PublicKey(nft.data.creators[0].address);

    const associatedAddress = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      nft.mint,
      publicKey
    );

    await stakeNft(
      new PublicKey(bank.farmId),
      connection,
      wallet,
      nft.mint,
      associatedAddress,
      creator,
      publicKey,
      sendTransaction
    );

    await refreshAll();
    refreshAll();
  };

  const unstakeAction = async (nft) => {
    setLoading(true);
    await unstakeNft(
      new PublicKey(bank.farmId),
      connection,
      wallet,
      nft.mint,
      publicKey,
      sendTransaction,
      allFarmersFromWallet
    );

    await refreshAll();
    refreshAll();
  };

  const fetchAllFarmers = async () => {
    if (!publicKey) return;

    const gf = await initGemFarm(
      connection,
      wallet!.adapter as SignerWalletAdapter
    );

    setAllFarmersFromWallet(
      await gf.fetchAllFarmerPDAs(new PublicKey(bank.farmId), publicKey)
    );
  };

  const getStakedNfts = async () => {
    if (bank.farmId === null) {
      setStakedNfts(null);
    }

    if (publicKey) {
      const gf = await initGemFarm(
        connection,
        wallet!.adapter as SignerWalletAdapter
      );

      setTokenAmount(tokenAmount);

      const formatCurrentStakingNft = [];
      const farmers = await gf.fetchAllFarmerPDAs(
        new PublicKey(bank.farmId),
        publicKey
      );
      setAllFarmersFromWallet(farmers);
      if (farmers !== null) {
        for (const farmer of farmers) {
          const currentStakingNft = await populateVaultNFTs(
            connection,
            wallet!.adapter as SignerWalletAdapter,
            farmer
          );

          if (currentStakingNft) {
            formatCurrentStakingNft.push(
              ...currentStakingNft?.map((e: any) => {
                return {
                  name: e.externalMetadata.name,
                  pubkey: e.pubkey,
                  mint: e.mint,
                  image: e.externalMetadata.image,
                  isStaked: true,
                  farmer: farmer,
                  json: e.externalMetadata,
                };
              })
            );
          }
        }
      }

      setStakedNfts(formatCurrentStakingNft);
    }
  };

  const handleClickOutside = (e) => {
    // @ts-ignore
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const onHandleChange = async (event) => {
    let bankEvent = event;
    await setOption(bankEvent);
    setOpen(false);
  };

  // staked percentage
  const percCalc =
    ((Number(farmOne?.gemsStaked?.toString()) +
      Number(farmTwo?.gemsStaked?.toString()) +
      Number(farmThree?.gemsStaked?.toString()) +
      Number(farmFour?.gemsStaked?.toString())) /
      555) *
      100 ?? 0;
  const perc = percCalc.toFixed(2);

  if (!publicKey) {
    return (
      <PageWrapper>
        <PageContainer>
          <IntroContainer>
            <Container style={{ position: "relative", zIndex: 1 }}>
              {/* <img
                src={Logo}
                alt={"NOTI GANG"}
                width={200}
                style={{
                  display: "flex",
                  margin: "0 auto 50px",
                  textAlign: "center",
                }}
              /> */}
              {/* <div className="nes-bg">
                <h2>Ready to bake?</h2>
                <p>
                  A collection of 555 bells ringing their way through the Solana
                  ecosystem!
                </p>
              </div> */}
            </Container>
            <StakingButtonsContainer
              style={{ position: "relative", zIndex: 1 }}
            >
              <WalletMultiButton />
            </StakingButtonsContainer>
          </IntroContainer>
          <Container>
            <h3 style={{ marginTop: 50, textAlign: "right" }}>
              {perc}% Noti Gang Staked
            </h3>
            <ProgressBar now={Number(perc)} />
          </Container>
        </PageContainer>
      </PageWrapper>
    );
  } else {
    return (
      <>
        {loading && (
          <div
            style={{
              background: "rgba(39,39,39,0.75)",
              height: "100vh",
              position: "fixed",
              width: "100vw",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              zIndex: 10,
            }}
          >
            <Loading color={"#ffffff"} show={true} />
          </div>
        )}
        <PageWrapper>
          <WalletContainer>
            <WalletMultiButton />
          </WalletContainer>
          <PageContainer>
            <IntroContainer>
              <Container style={{ position: "relative", zIndex: 1 }}>
                {/* <img
                  src={Logo}
                  alt={"NOTI GANG"}
                  height={200}
                  style={{
                    display: "flex",
                    margin: "50px auto",
                    textAlign: "center",
                    borderRadius: 20,
                  }}
                /> */}
                {/* <p>
                  A collection of 555 bells ringing their way through the Solana
                  ecosystem!
                </p> */}
              </Container>
            </IntroContainer>
            <Container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 50,
                  marginBottom: 30,
                  justifyContent: "space-between",
                }}
              >
                <DropdownContainer ref={node} className="dropdown">
                  <DropdownBox onClick={() => setOpen(!open)}>
                    <p>{bank?.label}</p>
                    <span className="dropdown-arrow" />
                  </DropdownBox>
                  {open && (
                    <DropdownMenu>
                      {options.map((o) => (
                        <DropdownMenuItem
                          onClick={() => onHandleChange(o)}
                          disabled={o.value === bank.value}
                          key={o.value}
                        >
                          <p>{o.label}</p>
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenu>
                  )}
                </DropdownContainer>
                <h3 style={{ textAlign: "right" }}>{perc}% Noti Gang Staked</h3>
              </div>
              <ProgressBar now={Number(perc)} />
              <VaultsContainer>
                {stakedNfts?.length > 0 && (
                  <VaultWrapper>
                    <h2>Your jailed Noti Gang ({stakedNfts?.length ?? 0})</h2>
                    <VaultContainer>
                      {stakedNfts?.map((nft) => {
                        return (
                          <VaultItemAdvanced
                            conn={connection}
                            key={nft?.mint?.toString()}
                            nft={nft}
                            type={"staked"}
                            action={() => unstakeAction(nft)}
                            claimAction={() => claimAction(nft)}
                            bank={bank}
                          />
                        );
                      })}
                    </VaultContainer>
                  </VaultWrapper>
                )}
                {/* <button
                    className="button button-secondary"
                    style={{ display: "inline", width: 220, marginBottom: 40 }}
                    onClick={() => stakeAllAction()}
                  >
                    Stake All
                  </button> */}
                {unstakedNfts?.length > 0 && (
                  <VaultWrapper>
                    <h2>Your free Noti Gang, lets earn some $BELL</h2>{" "}
                    <VaultContainer>
                      {unstakedNfts?.map((nft) => (
                        <VaultItemAdvanced
                          conn={null}
                          key={nft?.mint?.toString()}
                          nft={nft}
                          type={"unstaked"}
                          action={() => stakeAction(nft)}
                          bank={bank}
                        />
                      ))}
                    </VaultContainer>
                  </VaultWrapper>
                )}
              </VaultsContainer>
            </Container>
          </PageContainer>
        </PageWrapper>
      </>
    );
  }
};

export default Home;

const PageWrapper = styled.div`
  margin-bottom: auto;
`;
const PageContainer = styled.div`
  .progress {
    background: rgba(255, 255, 255, 0.25);
  }
  .progress-bar {
    background-color: #fff !important;
  }

  h3 {
    font-size: 18px;
  }
`;

const DropdownContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 300px;
`;

const StatsBox = styled.div`
  background: #e9ecef;
  border-radius: 10px;
  color: #222;
  height: 100px;
  max-width: 300px;
  padding: 12px 16px;
  text-align: left;
  width: 100%;

  &:first-child {
    @media only screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  p {
    color: #222;
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  span {
    display: block;
    font-family: "Source Code Pro", sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
  }
`;

const DropdownBox = styled(StatsBox)`
  cursor: pointer;
  height: auto;
  margin: 0 !important;
  position: relative;

  p {
    color: #222;
    margin: 0 !important;
  }

  span {
    color: #222;
    font-size: 14px;
    font-weight: 400;
  }

  .dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 24px;
    top: 22px;
    width: 0;
  }
`;

const DropdownMenu = styled.div`
  background-color: rgb(7, 29, 42);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 12px;
  margin-top: 4px;
  max-height: 200px;
  max-width: 300px;
  overflow-y: auto;
  padding: 8px 4px;
  position: absolute;
  top: 100%;
  transition: all 0.2s ease 0s;
  width: 100%;
  z-index: 2;
`;

const DropdownMenuItem = styled.button`
  align-items: flex-start;
  background: transparent;
  /* background: rgba(255, 255, 255, 0.3); */
  border: 0;
  box-shadow: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 13px 24px;
  width: 100%;

  p {
    color: rgb(255, 255, 255);
    font-size: 16px;
    /* font-weight: 600; */
    margin: 0 !important;
  }

  span {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 12px;
  }

  &:disabled {
    background-color: rgb(7, 29, 42);

    p,
    span {
      color: rgb(112, 111, 120);
      font-weight: 400;
    }
  }
`;

const WalletContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const IntroContainer = styled.div`
  background: url(${Background}) #375b76;
  background-position: center;
  background-size: cover;
  color: #fff;
  min-height: 70vh;
  padding: 80px 0;
  position: relative;
  text-align: center;
  /* width: 100%; */

  @media only screen and (max-width: 768px) {
    /* padding: 20px; */
  }

  button {
    display: inline-flex;
  }

  h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 36px;
  }

  p {
    font-size: 20px;
    margin: 0 auto 50px;
    max-width: 600px;
  }
`;

const VaultsContainer = styled.div`
  /* display: flex; */
  /* flex-flow: row wrap; */
  /* justify-content: center; */
  margin: 50px auto;
  min-height: 460px;

  @media only screen and (min-width: 768px) {
    /* display: grid; */
    /* grid-gap: 8px; */
    /* grid-template-columns: repeat(2, 1fr); */
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 20px;
  }
`;

const VaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  h2 {
    font-size: 16px;
    text-align: left;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 768px) {
    &:first-child {
      order: 1;
    }

    &:last-child {
      order: 0;
    }
  }
`;

const VaultContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
  /* display: flex; */
  /* flex-flow: row wrap; */
  /* flex-direction: column; */
`;

const StakingButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 0;

  .button {
    background: #fff;
    color: #0b843c;
    margin-left: 8px;
    margin-right: 8px;

    &:hover {
      background: #fff;
      color: #0b843c;
    }

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
